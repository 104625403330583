import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Dialog, DialogContent, DialogTitle,
} from '@mui/material';
import { EntityFileForm } from './EntityFileForm';
import { ScreenErrorAlert } from '../../../components/ScreenErrorAlert';
import { useAuth } from '../../auth/AuthProvider';
import { EntityFileUpdateDto, NiobiAdminEntityFileApi } from '../NiobiAdminEntityFileApi';
import { EntityFile } from '../EntityFile';

export function EntityFileUploadDialog(props: {
  open: boolean,
  file: EntityFile,
  onClose: VoidFunction,
  onUpload: VoidFunction,
}) {
  const auth = useAuth();
  const api = useMemo(
    () => new NiobiAdminEntityFileApi(auth.user?.accessToken, auth.logout),
    [auth],
  );
  const {
    open, file, onClose, onUpload,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => setErrorMessage(undefined), [open]);

  const handleFormSubmission = (submission: EntityFileUpdateDto) => {
    setIsLoading(true);
    api.update(file.id, submission)
      .then(() => setErrorMessage(undefined))
      .then(onUpload)
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  };
  return (
    <Dialog open={open} onClose={onClose} className="entity-file-upload-dialog">
      <DialogTitle>
        Replace Document
      </DialogTitle>
      <Box />
      <DialogContent>
        <ScreenErrorAlert
          className="alert"
          isLoading={isLoading}
          errorMessage={errorMessage}
          onClose={() => setErrorMessage(undefined)}
        />
        {/* <Stack direction="column" spacing={0.5} marginBottom={2}> */}
        {/*  <Stack direction="row"> */}
        {/*    <Typography variant="caption" width={100}>Title</Typography> */}
        {/*    <Typography variant="body2">{file.title}</Typography> */}
        {/*  </Stack> */}
        {/*  <Stack direction="row"> */}
        {/*    <Typography variant="caption" width={100}>Entity</Typography> */}
        {/*    <Typography variant="body2">{file.document.entity.name}</Typography> */}
        {/*  </Stack> */}
        {/*  <Stack direction="row"> */}
        {/*    <Typography variant="caption" width={100}>Entity Type</Typography> */}
        {/*    <Typography variant="body2">{file.document.entity.entityType.name}</Typography> */}
        {/*  </Stack> */}
        {/* </Stack> */}
        <EntityFileForm
          defaultValues={file}
          isLoading={isLoading}
          onSubmit={handleFormSubmission}
        />
      </DialogContent>
    </Dialog>
  );
}

export default EntityFileUploadDialog;
