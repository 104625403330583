import {
  Alert, Button, IconButton, Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

export function ScreenErrorAlert(props: {
  className?: string,
  isLoading: boolean,
  errorMessage?: string,
  onRetry?: VoidFunction,
  onClose?: VoidFunction,
}) {
  const {
    className, errorMessage, isLoading, onRetry, onClose,
  } = props;
  if (isLoading || !errorMessage) return null;
  return (
    <Alert
      severity="error"
      className={className}
      action={(
        <Stack direction="row">
          {!!onRetry && (
            <Button
              color="error"
              onClick={onRetry}
            >
              Retry
            </Button>
          )}
          {!!onClose && (
            <IconButton
              color="error"
              onClick={onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Stack>
            )}
    >
      {errorMessage}
    </Alert>
  );
}

export default ScreenErrorAlert;
