import { Entity, EntityType } from '../entities/Entity';
import { User } from '../entities/User';

export type Document = {
  id: number;
  userId: string;
  user: User;
  entityId: string;
  entity: Entity;
  documentType: string;
  entityType: EntityType;
  type?: string;
  status: DocumentStatus;
  createdAt?: Date;
  updatedAt?: Date;
};

export enum DocumentStatus {
  WAITING_FOR_SUBMISSION = 'waiting_for_submission',
  WAITING_FOR_APPROVAL = 'waiting_for_approval',
  REJECTED = 'rejected',
  APPROVED = 'approved',
}

export const DocumentStatuses: DocumentStatus[] = Object.values(DocumentStatus);

export function formatDocumentStatus(status: DocumentStatus): string {
  switch (status) {
    case DocumentStatus.WAITING_FOR_SUBMISSION: return 'Waiting For Submission';
    case DocumentStatus.WAITING_FOR_APPROVAL: return 'Waiting For Approval';
    case DocumentStatus.REJECTED: return 'Rejected';
    case DocumentStatus.APPROVED: return 'Approved';
    default: {
      console.warn(`formatDocumentStatus unhandled status: ${status}`);
      return status;
    }
  }
}
