import axios from 'axios';
import * as qs from 'qs';
import { Paginated, PaginationQuery } from '../../Pagination';
import { Entity, EntityStatus } from './Entity';
import { ADMIN_API_HOST } from '../../Config';
import { addUnauthorizedInterceptor } from '../../ApiUtils';

export class NiobiAdminEntityApi {
  private readonly api;

  constructor(
    private readonly accessToken: string | undefined,
    private readonly onUnauthorized: VoidFunction,
  ) {
    this.api = axios.create({
      baseURL: `${ADMIN_API_HOST}/entity/`,
      headers: { Authorization: `Bearer ${accessToken}` },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    });
    addUnauthorizedInterceptor(this.api, onUnauthorized);
  }

  async findOne(id: number): Promise<Entity> {
    return this.api.get(`/${id}`)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to query entity', err.response?.data);
        throw new Error('Unable to fetch entity at the moment. Please try again later.');
      });
  }

  async findAll(query?: EntityQueryDto): Promise<Paginated<Entity>> {
    return this.api.get('/', {
      params: query,
    })
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to query entities', err.response?.data);
        throw new Error('Unable to fetch entities at the moment. Please try again later.');
      });
  }

  async update(id: number, update: EntityUpdateDto): Promise<Entity> {
    return this.api.patch(`/${id}`, update)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to update entity', err.response?.data);
        throw new Error('Unable to update entity at the moment. Please try again later.');
      });
  }
}

export type EntityQueryDto = {
  ids?: number[];
  nameLike?: string;
  registrationNumber?: string;
  entityTypeIds?: number[];
  entityActivityIds?: number[];
  moniteId?: string;
  type?: string;
  legalNameLike?: string;
  vatId?: string;
  legalEntityId?: string;
  taxIdentificationNumber?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  createdBefore?: Date;
  createdAfter?: Date;
  updatedBefore?: Date;
  updatedAfter?: Date;
  status?: EntityStatus;
  // user
  userFirstNameLike?: string;
  userLastNameLike?: string;
  userNameLike?: string;
  userEmail?: string;
} & PaginationQuery;

export type EntityUpdateDto = Omit<Partial<Entity>, 'id'>;
