import axios from 'axios';
import * as qs from 'qs';
import jwt_decode from 'jwt-decode';
import { AuthUser } from './AuthUser';
import { ADMIN_API_HOST } from '../../Config';
import { UNAUTHORIZED } from '../../ApiUtils';

const api = axios.create({
  baseURL: `${ADMIN_API_HOST}/auth/`,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

export default class NiobiAdminAuthApi {
  async login(email: string, password: string): Promise<AuthUser> {
    return api.post('/login', { email, password })
      .then((response) => response.data)
      .then((body) => {
        const decodedToken: any = jwt_decode(body.accessToken);
        return {
          accessToken: body.accessToken,
          email: decodedToken.email,
          displayName: decodedToken.displayName,
        };
      })
      .catch((err) => {
        const isClientError = err.response.status.toString().startsWith('4');
        if (isClientError && err.response.data.message) {
          throw new Error(err.response.data.message);
        }
        console.error('Failed to login', err.response?.data);
        throw new Error('Unable to login at the moment. Please try again later');
      });
  }

  async logout(): Promise<void> {
    // todo consider jwt token logout endpoint?
  }

  async isLoggedIn(accessToken?: string): Promise<boolean> {
    return api.get('/protectedHelloWorld', { headers: { Authorization: `Bearer ${accessToken}` } })
      .then(() => true)
      .catch((err) => {
        if (err.response?.status === UNAUTHORIZED) return false;
        console.log('Failed to check if logged in', err.response.data);
        throw err;
      });
  }
}
