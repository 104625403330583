import {
  Autocomplete, Box, debounce, Stack, Toolbar,
} from '@mui/material';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridSortItem } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import EntityTable from './EntityTable';
import { NiobiAdminEntityApi } from './NiobiAdminEntityApi';
import {
  Entity, EntityStatus, EntityStatuses, formatEntityStatus,
} from './Entity';
import { EntityStatusAction, getNewEntityStatus } from './EntityStatusEngine';
import { useAuth } from '../auth/AuthProvider';
import { ScreenErrorAlert } from '../../components/ScreenErrorAlert';
import { INPUT_DEBOUNCE_MS } from '../../Config';

export default function EntitiesScreen() {
  const auth = useAuth();
  const api = useMemo(() => new NiobiAdminEntityApi(auth.user?.accessToken, auth.logout), [auth]);
  const {
    isLoading,
    entities,
    totalNumEntities,
    errorMessage,
    refresh,
    setPaginationFilter,
    setSortFilter,
    setEntityStatusFilter,
    setEntityNameFilter,
    setUserFirstNameFilter,
    setUserLastNameFilter,
  } = useEntities();
  const [isLoadingStatusUpdate, setIsLoadingStatusUpdate] = useState(false);
  const [statusUpdateError, setStatusUpdateError] = useState<string>();

  const handleTableQueryChange = (
    paginationModel?: GridPaginationModel,
    sortModel?: GridSortItem[],
  ) => {
    setPaginationFilter(paginationModel);
    setSortFilter(sortModel);
  };

  const handleStatusFilterChange = (status: EntityStatus | null) => {
    setEntityStatusFilter(status || undefined);
  };
  const handleEntityNameFilterChange = (value?: string) => {
    setEntityNameFilter(value || undefined);
  };
  const handleUserFirstNameFilterChange = (value?: string) => {
    setUserFirstNameFilter(value || undefined);
  };
  const handleUserLastNameFilterChange = (value?: string) => {
    setUserLastNameFilter(value || undefined);
  };

  const handleStatusChange = (entity: Entity, action: EntityStatusAction) => {
    const newStatus = getNewEntityStatus(action);
    setIsLoadingStatusUpdate(true);
    api.update(entity.id, { status: newStatus })
      .then(() => refresh())
      .then(() => setStatusUpdateError(undefined))
      .catch((err) => setStatusUpdateError(err.message))
      .finally(() => setIsLoadingStatusUpdate(false));
  };

  return (
    <Box className="entities-screen">
      <h3>Entities</h3>
      <ScreenErrorAlert className="alert" isLoading={isLoading} errorMessage={errorMessage} onRetry={refresh} />
      <ScreenErrorAlert className="alert" isLoading={isLoadingStatusUpdate} errorMessage={statusUpdateError} onClose={() => setStatusUpdateError(undefined)} />
      <Toolbar disableGutters className="toolbar">
        <Stack direction="row" spacing={1}>
          <Autocomplete
            className="toolbar-input"
            disablePortal
            options={EntityStatuses}
            getOptionLabel={(option) => formatEntityStatus(option)}
              /* eslint-disable-next-line react/jsx-props-no-spreading */
            renderInput={(params) => <TextField {...params} label="Status" />}
            onChange={(event, value) => handleStatusFilterChange(value)}
          />
          <TextField
            className="toolbar-input"
            label="Entity Name"
            variant="outlined"
            onChange={debounce(
              (event) => handleEntityNameFilterChange(event?.target.value || undefined),
              INPUT_DEBOUNCE_MS,
            )}
          />
          <TextField
            className="toolbar-input"
            label="User First Name"
            variant="outlined"
            onChange={debounce(
              (event) => handleUserFirstNameFilterChange(event?.target.value || undefined),
              INPUT_DEBOUNCE_MS,
            )}
          />
          <TextField
            className="toolbar-input"
            label="User Last Name"
            variant="outlined"
            onChange={debounce(
              (event) => handleUserLastNameFilterChange(event?.target.value || undefined),
              INPUT_DEBOUNCE_MS,
            )}
          />
        </Stack>
      </Toolbar>
      <EntityTable
        isLoading={isLoading}
        entities={entities}
        totalNumItems={totalNumEntities}
        onQueryChange={handleTableQueryChange}
        onEntityStatusAction={handleStatusChange}
      />
    </Box>
  );
}

export function useEntities() {
  const auth = useAuth();
  const api = useMemo(() => new NiobiAdminEntityApi(auth.user?.accessToken, auth.logout), [auth]);

  const [isInitialRender, setIsInitialRender] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [entities, setEntities] = useState<Entity[]>([]);
  const [totalNumEntities, setTotalNumEntities] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [paginationFilter, setPaginationFilter] = useState<GridPaginationModel>();
  const [sortFilter, setSortFilter] = useState<GridSortItem[]>();
  const [entityStatusFilter, setEntityStatusFilter] = useState<EntityStatus>();
  const [entityNameFilter, setEntityNameFilter] = useState<string>();
  const [userFirstNameFilter, setUserFirstNameFilter] = useState<string>();
  const [userLastNameFilter, setUserLastNameFilter] = useState<string>();

  const fetchEntities = useCallback(() => {
    const pagination = paginationFilter;
    const sort = sortFilter;
    const status = entityStatusFilter;
    const entityName = entityNameFilter;
    const userFirstName = userFirstNameFilter;
    const userLastName = userLastNameFilter;

    const offset = pagination ? pagination.page * pagination.pageSize : undefined;
    const limit = pagination?.pageSize;
    const sortField = sort?.[0]?.field || 'id';
    const sortAsc = sort?.[0]?.sort ? sort[0].sort !== 'desc' : false;

    setIsLoading(true);
    api.findAll({
      offset,
      limit,
      sortField,
      sortAsc,
      status,
      nameLike: entityName,
      userFirstNameLike: userFirstName,
      userLastNameLike: userLastName,
    })
      .then((response) => {
        setEntities(response.items);
        setTotalNumEntities(response.paginationInfo.totalNumItems);
      })
      .then(() => setErrorMessage(undefined))
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  }, [
    api,
    paginationFilter,
    sortFilter,
    entityStatusFilter,
    entityNameFilter,
    userFirstNameFilter,
    userLastNameFilter,
  ]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    fetchEntities();
  }, [fetchEntities, isInitialRender]);

  return {
    isLoading,
    entities,
    totalNumEntities,
    errorMessage,
    refresh: fetchEntities,
    setPaginationFilter,
    setSortFilter,
    setEntityStatusFilter,
    setEntityNameFilter,
    setUserFirstNameFilter,
    setUserLastNameFilter,
  };
}
