import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { GridColDef, GridSortItem } from '@mui/x-data-grid';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import moment from 'moment';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BlockIcon from '@mui/icons-material/Block';
import CloseIcon from '@mui/icons-material/Close';
import NiobiTable from '../../components/NiobiTable';
import {
  Entity, EntityStatus, EntityType, formatEntityStatus,
} from './Entity';
import { EntityStatusAction, getEntityStatusActions } from './EntityStatusEngine';
import { User } from './User';
import { useAuth } from '../auth/AuthProvider';
import { NiobiAdminEntityFileApi } from '../entityfiles/NiobiAdminEntityFileApi';

export default function EntityTable(props: {
  isLoading: boolean,
  entities: Entity[],
  totalNumItems: number,
  onQueryChange: (paginationModel?: GridPaginationModel, sortModel?: GridSortItem[]) => any,
  onEntityStatusAction: OnEntityStatusAction,
}) {
  const {
    isLoading,
    onQueryChange,
    totalNumItems,
    entities,
    onEntityStatusAction,
  } = props;
  return (
    <NiobiTable
      isLoading={isLoading}
      columns={columns(onEntityStatusAction)}
      rows={entities}
      totalNumItems={totalNumItems}
      onQueryChange={onQueryChange}
    />
  );
}

const columns = (onEntityStatusAction: OnEntityStatusAction): GridColDef[] => [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 200 },
  {
    field: 'entityType.name',
    headerName: 'Type',
    width: 130,
    valueGetter: (params) => (params.row.entityType as EntityType)?.name,
  },
  {
    field: 'user.firstName',
    headerName: 'User',
    width: 200,
    valueGetter: (params) => {
      const user = (params.row.user as User);
      return `${user?.firstName || ''} ${user?.lastName || ''}`;
    },
  },
  {
    field: 'user.email',
    headerName: 'Email',
    flex: 1,
    valueGetter: (params) => (params.row.user as User)?.email,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 180,
    valueFormatter: (params) => moment(params.value).format('DD MMM YYYY, HH:mm'),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    align: 'center',
    renderCell: (params) => <EntityStatusChip status={(params.row as Entity).status} />,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <EntityTableRowActions
        entity={params.row as Entity}
        onStatusAction={onEntityStatusAction}
      />
    ),
    align: 'right',
  },
];

function EntityStatusChip(props: { status: EntityStatus }) {
  const { status } = props;
  const getColor = () => {
    switch (status) {
      case EntityStatus.PENDING: return 'info';
      case EntityStatus.VERIFIED: return 'success';
      case EntityStatus.REJECTED: return 'warning';
      case EntityStatus.SUSPENDED: return 'error';
      case EntityStatus.PERMANENTLY_SUSPENDED: return 'error';
      default: {
        console.warn(`EntityStatusChip unhandled status: ${status}`);
        return undefined;
      }
    }
  };
  const getIcon = () => {
    switch (status) {
      case EntityStatus.PENDING: return <PersonAddIcon />;
      case EntityStatus.VERIFIED: return <CheckIcon />;
      case EntityStatus.REJECTED: return <CloseIcon />;
      case EntityStatus.SUSPENDED: return <BlockIcon />;
      case EntityStatus.PERMANENTLY_SUSPENDED: return <BlockIcon />;
      default: {
        console.warn(`EntityStatusChip unhandled status: ${status}`);
        return undefined;
      }
    }
  };
  return (
    <Tooltip title={formatEntityStatus(status)}>
      <Chip
        sx={{ width: '100%' }}
        label={formatEntityStatus(status)}
        variant="outlined"
        icon={getIcon()}
        color={getColor()}
      />
    </Tooltip>
  );
}

export type OnEntityStatusAction = (entity: Entity, action: EntityStatusAction) => any;

function EntityTableRowActions(props: {
  entity: Entity,
  onStatusAction: OnEntityStatusAction,
}) {
  const auth = useAuth();
  const api = useMemo(
    () => new NiobiAdminEntityFileApi(auth.user?.accessToken, auth.logout),
    [auth],
  );
  const { entity, onStatusAction } = props;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isLoadingActions, setIsLoadingActions] = React.useState<boolean>(true);
  const [entityStatusActions, setEntityStatusActions] = React.useState<EntityStatusAction[]>([]);
  const [menuOpened, setMenuOpened] = React.useState<boolean>(false);
  const [actionToConfirm, setActionToConfirm] = React.useState<EntityStatusAction>();

  useEffect(() => {
    if (!menuOpened) return;
    setIsLoadingActions(true);
    setEntityStatusActions([]);
    getEntityStatusActions(api, entity.id.toString(), entity.status)
      .then(setEntityStatusActions)
      .finally(() => setIsLoadingActions(false));
  }, [api, entity, menuOpened]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpened(true);
  };

  const handleMenuClose = () => setMenuAnchorEl(null);

  const handleSelectAction = (action: EntityStatusAction) => {
    setActionToConfirm(action);
  };

  const handleCancelAction = () => {
    handleMenuClose();
    setActionToConfirm(undefined);
  };

  const handleConfirmAction = () => {
    onStatusAction(entity, actionToConfirm!);
    handleMenuClose();
    handleCancelAction();
  };

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleMenuClose}
      >
        {isLoadingActions && <MenuItem disabled><CircularProgress /></MenuItem>}
        {entityStatusActions.map((action) => (
          <MenuItem key={action} onClick={() => handleSelectAction(action)}>{action}</MenuItem>
        ))}
      </Menu>
      <EntityStatusChangeAlertDialog
        open={!!actionToConfirm}
        onClose={handleCancelAction}
        onConfirm={handleConfirmAction}
      />
    </>
  );
}

function EntityStatusChangeAlertDialog(props: {
  open: boolean,
  onClose: VoidFunction,
  onConfirm: VoidFunction,
}) {
  const { open, onClose, onConfirm } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Update Entity Status
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to update the entity status?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={onConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
