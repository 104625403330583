import * as React from 'react';
import { GridColDef, GridSortItem } from '@mui/x-data-grid';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import moment from 'moment';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PendingIcon from '@mui/icons-material/Pending';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Link from '@mui/material/Link';
import NiobiTable from '../../components/NiobiTable';
import { EntityFile, EntityFileStatus, formatEntityFileStatus } from './EntityFile';
import { EntityFileStatusAction, getEntityFileStatusActions } from './EntityFileStatusEngine';

export default function EntityFileTable(props: {
  isLoading: boolean,
  files: EntityFile[],
  totalNumItems: number,
  onQueryChange: (paginationModel?: GridPaginationModel, sortModel?: GridSortItem[]) => any,
  onEntityFileStatusAction: OnEntityFileStatusAction,
  onClickUpdate: (file: EntityFile) => any,
}) {
  const {
    isLoading,
    onQueryChange,
    totalNumItems,
    files,
    onEntityFileStatusAction,
    onClickUpdate,
  } = props;
  return (
    <NiobiTable
      isLoading={isLoading}
      columns={columns(onEntityFileStatusAction, onClickUpdate)}
      rows={files}
      totalNumItems={totalNumItems}
      onQueryChange={onQueryChange}
    />
  );
}

const columns = (
  onEntityFileStatusAction: OnEntityFileStatusAction,
  onClickUpdate: (file: EntityFile) => any,
): GridColDef[] => [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    renderCell: (params) => {
      const file = params.row as EntityFile;
      return <Link href={file.url} target="_blank">{file.title}</Link>;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 180,
    valueFormatter: (params) => moment(params.value).format('DD MMM YYYY, HH:mm'),
  },
  {
    field: 'document.user.firstName',
    headerName: 'User',
    width: 130,
    valueGetter: (params) => {
      const file = params.row as EntityFile;
      const user = file?.document?.user;
      if (!user) return undefined;
      return `${user.firstName} ${user.lastName}`;
    },
  },
  {
    field: 'document.entity.name',
    headerName: 'Entity Name',
    width: 200,
    valueGetter: (params) => (params.row as EntityFile).document?.entity?.name,
  },
  {
    field: 'document.entityType.name',
    headerName: 'Entity Type',
    width: 200,
    valueGetter: (params) => (params.row as EntityFile).document?.entityType?.name,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    align: 'center',
    renderCell: (params) => <EntityFileStatusChip status={(params.row as EntityFile).status} />,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <EnfityFileTableRowActions
        file={params.row as EntityFile}
        onStatusAction={onEntityFileStatusAction}
        onClickUpdate={onClickUpdate}
      />
    ),
    align: 'right',
  },
];

function EntityFileStatusChip(props: { status: EntityFileStatus }) {
  const { status } = props;
  const getColor = () => {
    switch (status) {
      case EntityFileStatus.WAITING_FOR_SUBMISSION: return 'warning';
      case EntityFileStatus.WAITING_FOR_APPROVAL: return 'info';
      case EntityFileStatus.REJECTED: return 'error';
      case EntityFileStatus.APPROVED: return 'success';
      default: {
        console.warn(`EntityFileStatusChip unhandled status: ${status}`);
        return undefined;
      }
    }
  };
  const getIcon = () => {
    switch (status) {
      case EntityFileStatus.WAITING_FOR_SUBMISSION: return <PendingIcon />;
      case EntityFileStatus.WAITING_FOR_APPROVAL: return <PostAddIcon />;
      case EntityFileStatus.REJECTED: return <CloseIcon />;
      case EntityFileStatus.APPROVED: return <CheckIcon />;
      default: {
        console.warn(`EntityFileStatusChip unhandled status: ${status}`);
        return undefined;
      }
    }
  };
  return (
    <Tooltip title={formatEntityFileStatus(status)}>
      <Chip
        sx={{ width: '100%' }}
        label={formatEntityFileStatus(status)}
        variant="outlined"
        icon={getIcon()}
        color={getColor()}
      />
    </Tooltip>
  );
}

export type OnEntityFileStatusAction = (file: EntityFile, action: EntityFileStatusAction) => any;

function EnfityFileTableRowActions(props: {
  file: EntityFile,
  onStatusAction: OnEntityFileStatusAction,
  onClickUpdate: (file: EntityFile) => any,
}) {
  const { file, onStatusAction, onClickUpdate } = props;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [actionToConfirm, setActionToConfirm] = React.useState<EntityFileStatusAction>();

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setMenuAnchorEl(null);

  const handleSelectAction = (action: EntityFileStatusAction) => {
    setActionToConfirm(action);
  };

  const handleCancelAction = () => {
    handleMenuClose();
    setActionToConfirm(undefined);
  };

  const handleConfirmAction = () => {
    onStatusAction(file, actionToConfirm!);
    handleMenuClose();
    handleCancelAction();
  };

  const handleUpdateClick = () => {
    onClickUpdate(file);
    handleMenuClose();
  };

  const entityStatusActions = getEntityFileStatusActions(file.status);

  return (
    <>
      {file.status !== EntityFileStatus.APPROVED && (
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
      )}
      <Menu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleMenuClose}
      >
        {entityStatusActions.map((action) => (
          <MenuItem key={action} onClick={() => handleSelectAction(action)}>{action}</MenuItem>
        ))}
        {file.status === EntityFileStatus.REJECTED && (
          <MenuItem onClick={handleUpdateClick}>Upload</MenuItem>
        )}
      </Menu>
      <EntityFileStatusChangeAlertDialog
        open={!!actionToConfirm}
        onClose={handleCancelAction}
        onConfirm={handleConfirmAction}
      />
    </>
  );
}

function EntityFileStatusChangeAlertDialog(props: {
  open: boolean,
  onClose: VoidFunction,
  onConfirm: VoidFunction,
}) {
  const { open, onClose, onConfirm } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Update File Status
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to update the file status?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={onConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
