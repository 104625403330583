import { Document } from './Document';

export type EntityFile = {
  id: number;
  documentId: string;
  document: Document;
  title?: string;
  url?: string;
  status: EntityFileStatus;
  uploadedAt: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export enum EntityFileStatus {
  WAITING_FOR_SUBMISSION = 'waiting_for_submission',
  WAITING_FOR_APPROVAL = 'waiting_for_approval',
  REJECTED = 'rejected',
  APPROVED = 'approved',
}

export const EntityFileStatuses: EntityFileStatus[] = Object.values(EntityFileStatus);

export function formatEntityFileStatus(status: EntityFileStatus): string {
  switch (status) {
    case EntityFileStatus.WAITING_FOR_SUBMISSION: return 'Waiting For Submission';
    case EntityFileStatus.WAITING_FOR_APPROVAL: return 'Waiting For Approval';
    case EntityFileStatus.REJECTED: return 'Rejected';
    case EntityFileStatus.APPROVED: return 'Approved';
    default: {
      console.warn(`formatEntityFileStatus unhandled status: ${status}`);
      return status;
    }
  }
}
