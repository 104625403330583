import { EntityStatus } from './Entity';
import { NiobiAdminEntityFileApi } from '../entityfiles/NiobiAdminEntityFileApi';
import { EntityFileStatus } from '../entityfiles/EntityFile';

export enum EntityStatusAction {
  VERIFY = 'Verify',
  REJECT = 'Reject',
  SUSPEND = 'Suspend',
  PERMANENT_SUSPEND = 'Permanent Suspend',
  ACTIVATE = 'Activate',
}

export async function getEntityStatusActions(
  fileApi: NiobiAdminEntityFileApi,
  entityId: string,
  entityStatus: EntityStatus,
): Promise<EntityStatusAction[]> {
  switch (entityStatus) {
    case EntityStatus.PENDING:
      return [
        ...await canVerifyEntity(fileApi, entityId) ? [EntityStatusAction.VERIFY] : [],
        EntityStatusAction.REJECT,
        EntityStatusAction.PERMANENT_SUSPEND,
      ];
    case EntityStatus.VERIFIED: return [
      EntityStatusAction.SUSPEND,
      EntityStatusAction.PERMANENT_SUSPEND,
    ];
    case EntityStatus.REJECTED: return [
      ...await canVerifyEntity(fileApi, entityId) ? [EntityStatusAction.VERIFY] : [],
    ];
    case EntityStatus.SUSPENDED: return [EntityStatusAction.ACTIVATE];
    case EntityStatus.PERMANENTLY_SUSPENDED: return [];
    default: {
      console.warn(`getEntityStatusActions unhandled status: ${entityStatus}`);
      return [];
    }
  }
}

async function canVerifyEntity(
  fileApi: NiobiAdminEntityFileApi,
  entityId: string,
): Promise<boolean> {
  return fileApi.findAll({
    documentEntityIds: [entityId],
    limit: 100,
  })
    .then((response) => {
      const entityFiles = response.items;
      return entityFiles.length > 0
            && entityFiles.every((file) => file.status === EntityFileStatus.APPROVED);
    })
    .catch((err) => {
      console.log('Failed to get entity files', err);
      return false;
    });
}

export function getNewEntityStatus(action: EntityStatusAction): EntityStatus {
  switch (action) {
    case EntityStatusAction.VERIFY: return EntityStatus.VERIFIED;
    case EntityStatusAction.REJECT: return EntityStatus.REJECTED;
    case EntityStatusAction.SUSPEND: return EntityStatus.SUSPENDED;
    case EntityStatusAction.PERMANENT_SUSPEND: return EntityStatus.PERMANENTLY_SUSPENDED;
    case EntityStatusAction.ACTIVATE: return EntityStatus.VERIFIED;
    default: {
      console.error(`getNewEntityStatus unhandled action: ${action}`);
      return undefined as unknown as EntityStatus;
    }
  }
}
