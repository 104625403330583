import React from 'react';
import { Outlet } from 'react-router-dom';
import NiobiAppBar from './NiobiAppBar';
import NiobiDrawer from './NiobiDrawer';

export default function DashboardScreen() {
  return (
    <div className="dashboard-screen">
      <NiobiAppBar />
      <div className="body-container">
        <NiobiDrawer />
        <div className="content-container">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
