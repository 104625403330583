import React, {
  ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import MockAuthStorage from './MockAuthStorage';
import { AuthUser } from './AuthUser';
import NiobiAdminAuthApi from './NiobiAdminAuthApi';

// https://github.com/remix-run/react-router/tree/dev/examples/auth

export type AuthContextType = {
  isLoading: boolean;
  user?: AuthUser;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
};

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider(props: { children: ReactNode }) {
  const { children } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<AuthUser>();

  const authStorage = useMemo(() => new MockAuthStorage(), []);
  const authApi = useMemo(() => new NiobiAdminAuthApi(), []);

  const login = useMemo(() => (email: string, password: string) => authApi.login(email, password)
    .then(async (userResponse) => {
      await authStorage.saveUser(userResponse);
      setUser(userResponse);
    }), [authApi, authStorage]);

  const logout = useMemo(() => () => authApi.logout()
    .then(async () => {
      await authStorage.deleteUser();
      setUser(undefined);
    }), [authApi, authStorage]);

  useEffect(() => {
    setIsLoading(true);
    authStorage.getUser()
      .then(async (userResponse) => {
        const isLoggedIn = await authApi.isLoggedIn(userResponse?.accessToken);
        if (isLoggedIn) setUser(userResponse || undefined);
        else await logout();
      })
      .catch(() => logout())
      .finally(() => setIsLoading(false));
  }, [authStorage, authApi, logout]);

  const value: AuthContextType = useMemo(() => ({
    isLoading, user, login, logout,
  }), [isLoading, user, login, logout]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
