import { AxiosInstance } from 'axios';

export const UNAUTHORIZED = 401;

export function addUnauthorizedInterceptor(api: AxiosInstance, onUnauthorized: VoidFunction) {
  api.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === UNAUTHORIZED) {
      onUnauthorized();
      return;
    }
    throw error;
  });
}
