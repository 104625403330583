import { AuthUser } from './AuthUser';

export default class MockAuthStorage {
  private readonly USER_KEY = 'USER';

  private static async delay(ms = 2000) {
    await new Promise((resolve) => { setTimeout(resolve, ms); });
  }

  async saveUser(user: AuthUser): Promise<void> {
    await MockAuthStorage.delay();
    localStorage.setItem(this.USER_KEY, JSON.stringify(user));
  }

  async deleteUser(): Promise<void> {
    await MockAuthStorage.delay();
    localStorage.removeItem(this.USER_KEY);
  }

  async getUser(): Promise<AuthUser | null> {
    await MockAuthStorage.delay();
    const user = localStorage.getItem(this.USER_KEY);
    if (!user) return null;
    return JSON.parse(user);
  }
}
