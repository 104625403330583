import * as React from 'react';
import { useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Controller, useForm } from 'react-hook-form';
import { CircularProgress, InputAdornment } from '@mui/material';
import { EntityFileUpdateDto } from '../NiobiAdminEntityFileApi';
import { EntityFileStatus } from '../EntityFile';
import { DocumentStatus } from '../Document';
import { NiobiAdminFileStorageApi } from '../NiobiAdminFileStorageApi';
import { useAuth } from '../../auth/AuthProvider';

export function EntityFileForm(props: {
  isLoading?: boolean,
  defaultValues?: EntityFileUpdateDto,
  onSubmit: (submission: EntityFileUpdateDto) => any,
}) {
  const { isLoading, onSubmit, defaultValues } = props;
  const auth = useAuth();
  const api = useMemo(
    () => new NiobiAdminFileStorageApi(auth.user?.accessToken, auth.logout),
    [auth],
  );
  const { handleSubmit, control } = useForm<EntityFileUpdateDto>({
    defaultValues: {
      ...defaultValues,
    },
  });
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [fileUploadError, setFileUploadError] = useState<string>();

  const handleFileUpload = (file: File | undefined, onUpload: (url?: string) => any) => {
    if (!file) {
      onUpload(undefined);
      return;
    }
    setIsUploadingFile(true);
    api
      .upload(file)
      .then((url) => onUpload(url))
      .then(() => setFileUploadError(undefined))
      .catch((err) => setFileUploadError(err.message))
      .finally(() => setIsUploadingFile(false));
  };

  const prepareSubmission = (submission: EntityFileUpdateDto) => {
    const dto: EntityFileUpdateDto = {
      title: submission.title,
      url: submission.url,
      status: EntityFileStatus.WAITING_FOR_APPROVAL,
      document: {
        userId: submission.document?.userId,
        entityId: submission.document?.entityId,
        documentType: submission.document?.documentType,
        type: submission.document?.type,
        status: DocumentStatus.WAITING_FOR_APPROVAL,
      },
    };
    onSubmit(dto);
  };

  return (
    <form className="entity-file-form" onSubmit={handleSubmit(prepareSubmission)}>
      <Controller
        name="url"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            autoFocus
            className="input"
            variant="outlined"
            value={value}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    component="label"
                  >
                    Upload File
                    <input
                      type="file"
                      hidden
                      onChange={(event) => {
                        const file = event?.target?.files?.[0];
                        handleFileUpload(file, onChange);
                      }}
                    />
                  </Button>
                </InputAdornment>
              ),
            }}
            error={!!error || !!fileUploadError}
            helperText={error?.message || fileUploadError || null}
            disabled
            fullWidth
          />
        )}
        rules={{
          required: 'File required',
        }}
      />
      <Button
        className="action-button"
        type="submit"
        variant="contained"
        disabled={isLoading || isUploadingFile}
      >
        {(isLoading || isUploadingFile) ? (<CircularProgress />) : 'Submit'}
      </Button>
    </form>
  );
}

export default EntityFileForm;
