import { AppBar, CircularProgress, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useAuth } from '../auth/AuthProvider';

export default function NiobiAppBar() {
  const auth = useAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const Loader = (
    <CircularProgress sx={(theme) => ({
      color: theme.palette.primary.contrastText,
    })}
    />
  );
  return (
    <AppBar className="niobi-app-bar" position="relative" elevation={0}>
      <Toolbar>
        <Typography variant="h6" fontWeight={600} flexGrow={1}>
          Niobi Admin
        </Typography>
        <Button
          variant="contained"
          disabled={isLoggingOut}
          onClick={() => {
            setIsLoggingOut(true);
            auth.logout()
              .finally(() => setIsLoggingOut(false));
          }}
        >
          {isLoggingOut ? Loader : 'Logout'}
        </Button>
      </Toolbar>
    </AppBar>
  );
}
