import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';

export default function RequireAuth(props: { children: JSX.Element }): JSX.Element {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace /> as JSX.Element;
  }

  return children;
}
