import { EntityFileStatus } from './EntityFile';

// todo confirm entity file status engine

export enum EntityFileStatusAction {
  REJECT = 'Reject',
  APPROVE = 'Approve',
}

export function getEntityFileStatusActions(status: EntityFileStatus): EntityFileStatusAction[] {
  switch (status) {
    case EntityFileStatus.WAITING_FOR_SUBMISSION: return [];
    case EntityFileStatus.WAITING_FOR_APPROVAL: return [
      EntityFileStatusAction.APPROVE,
      EntityFileStatusAction.REJECT,
    ];
    case EntityFileStatus.APPROVED: return [];
    case EntityFileStatus.REJECTED: return [];
    default: {
      console.warn(`getEntityFileStatusActions unhandled status: ${status}`);
      return [];
    }
  }
}

export function getNewEntityFileStatus(action: EntityFileStatusAction): EntityFileStatus {
  switch (action) {
    case EntityFileStatusAction.REJECT: return EntityFileStatus.REJECTED;
    case EntityFileStatusAction.APPROVE: return EntityFileStatus.APPROVED;
    default: {
      console.error(`getNewEntityFileStatus unhandled action: ${action}`);
      return undefined as unknown as EntityFileStatus;
    }
  }
}
