import { User } from './User';

export type Entity = {
  id: number;
  name: string;
  registrationNumber: string;
  userId: string;
  user: User;
  entityTypeId: number;
  entityType: EntityType;
  entityActivityId: number;
  entityActivity: EntityActivity;
  description?: string;
  moniteId?: string;
  type?: string;
  legalName?: string;
  vatId?: string;
  legalEntityId?: string;
  taxIdentificationNumber?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  createdAt?: Date;
  updatedAt?: Date;
  status: EntityStatus;
};

export enum EntityStatus {
  PENDING = 'pending',
  VERIFIED = 'verified',
  REJECTED = 'rejected',
  SUSPENDED = 'suspended',
  PERMANENTLY_SUSPENDED = 'permanently_suspended',
}

export const EntityStatuses: EntityStatus[] = Object.values(EntityStatus);

export function formatEntityStatus(status: EntityStatus): string {
  switch (status) {
    case EntityStatus.PENDING: return 'Pending';
    case EntityStatus.VERIFIED: return 'Verified';
    case EntityStatus.REJECTED: return 'Rejected';
    case EntityStatus.SUSPENDED: return 'Suspended';
    case EntityStatus.PERMANENTLY_SUSPENDED: return 'Permanently Suspended';
    default: {
      console.warn(`formatEntityStatus unhandled status: ${status}`);
      return status;
    }
  }
}

export type EntityActivity = {
  id: number;
  name: string;
  description?: string;
};

export type EntityType = {
  id: number;
  name: string;
  description?: string;
};
